.card {
  padding-bottom: 60px;

  & * {
    box-shadow: none !important;
  }

  &__block {
    border-radius: 20px;
    background: #fff;
    padding: 40px;
    margin-bottom: 60px;

    &:first-of-type {
      padding-top: 94px;
      margin-top: -54px;
    }
  }

  &__back {
    cursor: pointer;
    margin-right: 40px;
  }

  &__title {
    color: #258664;
    font-size: 30px;
    font-weight: 500;

    &-wrap {
      display: flex;
      align-items: center;
      user-select: none;
      transition: all 0.4s ease;
      margin-bottom: 30px;

      .block & {
        cursor: pointer;
      }

      .registration & {
        margin-bottom: 20px;
      }
    }
  }

  &__step {
    color: #284657;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  &__activity {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-start;
    margin-left: 10px;
  }

  &__status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    align-self: flex-start;
    margin-left: 10px;

    &.Inactive {
      background: #ea1919;
    }

    &.Working {
      background: #f98f12;
    }

    &.Active {
      background: #268664;
    }
  }
}

.personal {
  .cleaning & {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__wrap {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s ease;
    user-select: none;

    .personal.expanded & {
      margin-bottom: 16px;
    }
  }

  &__title {
    color: #268664;
    font-size: 20px;
    font-weight: 500;
  }

  &__info {
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;

    .personal.expanded & {
      max-height: 1000px;
    }
  }

  &__values {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      font-weight: 500;
      color: #284657;
      margin-right: 20px;

      & a {
        color: #284657;

        &:hover {
          color: #268664;
        }
      }
    }
  }

  &__text {
    margin-bottom: 8px;
    align-self: flex-start;

    &_warning {
      font-weight: 500;
      margin-right: 8px;
      color: #be0f0f;
    }
  }

  &__add {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    align-self: flex-start;

    &-icon {
      margin-top: -2px;
    }

    &-label {
      margin-left: 6px;
      color: #268664;
      font-size: 16px;
      font-weight: 500;
      margin-top: -2px;
    }
  }
}

span.personal__text:last-of-type {
  margin-bottom: 0;
}

.arrow {
  margin-right: 10px;
  transition: transform 0.4s ease;
  cursor: pointer;

  &.rotated {
    transform: rotate(180deg);
  }
}

.kpi {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  &__block {
    padding: 20px;
    height: 94px;
    border-radius: 6px;
    border: 1px solid #2329d61a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .customer &,
    .cleaner & {
      width: calc((100% - 60px) / 7);
    }

    .agent & {
      width: calc((100% - 50px) / 6.2);

      &:nth-of-type(3) {
        width: calc((100% - 50px) / 5);
      }
    }

    .address &,
    .subscription & {
      width: calc((100% - 40px) / 5);
    }
  }

  &__label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  &__value {
    color: #284657;
    font-size: 20px;
    font-weight: 500;
    align-self: flex-start;
  }
}

.block {
  &__table {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-top: 20px;

    .block.expanded & {
      max-height: 5000px;
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }
}

.customer .block:nth-last-of-type(4) .data__table,
.address .block:nth-last-of-type(1) .data__table,
.cleaner .block:nth-last-of-type(1) .data__table,
.cleaning .block:nth-last-of-type(1) .data__table,
.agent .block:nth-last-of-type(1) .data__table {
  margin-top: 0;
}
