.settings {
  height: calc(100vh - 114px);

  &__block {
    padding: 20px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 300px;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(0, 9, 255, 0.03);
    }
  }

  &__subtitle {
    color: #78878f;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }

  &__link {
    margin-bottom: 5px;
    height: 17px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & a {
      color: #2329d6;
      font-size: 14px;
      line-height: normal;
      position: relative;

      &::after {
        display: block;
        content: '';
        background: transparent;
        width: 100%;
        height: 1px;
        bottom: 0;
        position: absolute;
        transition: all 0.3s ease;
      }

      &:hover::after {
        background: #2329d6;
      }
    }
  }
}
