.accounting {
  animation: all 0.3s ease-in;
  padding-bottom: 100px;

  &__title {
    color: var(--new-black, #303030);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .card__title-wrap {
    justify-content: space-between;

    min-height: 42px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .block {
    margin-top: 20px;
    .card__title {
      &-wrap {
        &.perCleaner {
          justify-content: space-between;
        }
      }
      &-item {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: flex-start;
      }

      &-perCleaner {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: center;
        gap: 20px;
        z-index: 2;
      }
    }
  }
  .total {
    .info {
      padding-bottom: 40px;
      .profit {
        .label {
          color: #2B2C2F;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 130%;
        }
        .price__profit {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          &_text {
            color: #2B2C2F;
            font-feature-settings: 'liga' off, 'clig' off;

            font-family: Inter, Helvetica;
            font-size: 48px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
          }

          .price__profit_percent {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 3px;

            span {
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: Inter, Helvetica;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 130%;
            }
            &.plus {
              span {
                color: #4BAF9D;
              }
            }
            &.minus {
              span {
                color: #F4825E;
              }
            }
          }
        }
      }

      .profit__details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .label {
          color: #2B2C2F;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 130%;
        }

        .priceItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .price {
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter, Helvetica;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;

            &.revenue {
              color: #31A64B;
            }
            &.expenses {
              color: #E34643;
            }
          }
        }

        .prices__details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding-left: 80px;
          border-left: 1px solid rgba(0, 0, 0, 0.20);

          span {
            color: #2B2C2F;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter, Helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;

            &.increase {
              color: #31A64B;
            }
            &.decreased {
              color: #E34643;
            }
          }
        }
      }
    }
  }
  .date_range {
    .dateRange__dropdown {
      min-width: 170px;
      border-radius: 8px;
      border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
      padding: 8px 16px;
      cursor: pointer;

      &:focus {
        box-shadow: unset;
      }

      .calendarSvg__icon {
        padding-right: 16px;
      }

      .react-dropdown-select-dropdown {
        padding: 4px;
        border-radius: 2px;
        border: 1px solid rgba(37, 134, 100, 0.10);
        background: #E6F0EC;
        box-shadow: 0px 7px 7px -5px rgba(0, 0, 0, 0.04), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);

        span {
          color: #2B2C2F;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
          border: none;
          padding: 16px;

          &.react-dropdown-select-item-selected {
            background: #F7FBFA;
            font-weight: 600;
          }
        }
      }
    }
    .dateRange__calendar {
      position: absolute;
      right: 0;
      font-size: 14px;
      color: #2B2C2F;
      padding: 4px;
      margin-top: 4px;
      border-radius: 2px;
      border: 1px solid rgba(37, 134, 100, 0.10);
      background: #FFF;
      box-shadow: 0px 7px 7px -5px rgba(0, 0, 0, 0.04), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);

      .rdrMonthAndYearWrapper {
        height: 52px;
        background-color: #E6F0EC;
        padding: 0;

        .rdrNextPrevButton {
          background: transparent;
          height: 52px;
        }

        .rdrMonthAndYearPickers {
          select {
            background: transparent;
          }

          .rdrMonthPicker {
            margin: 0;
            select {
              padding: 10px 2px 10px 20px;
            }
          }
          .rdrYearPicker {
            margin: 0;
            select {
              padding: 10px 20px 10px 2px;
            }
          }
        }
      }
      .rdrMonths {
        .rdrMonth {
          padding: 0;

          .rdrWeekDays {
            padding: 16px 0;

            .rdrWeekDay {
              color: #2B2C2F;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: Inter, Helvetica;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
            }
          }

          .rdrDays {

            .rdrDay {
              color: #4BAF9D;
              border-radius: 6px;

              &.rdrDayEndOfWeek {
                .rdrDayInPreview {
                  border-top-right-radius: 6px;
                  border-bottom-right-radius: 6px;
                }
                .rdrInRange {
                  border-radius: 0;
                }
              }

              &.rdrDayStartOfWeek {
                .rdrDayInPreview {
                  border-top-left-radius: 6px;
                  border-bottom-left-radius: 6px;
                }
                .rdrInRange {
                  border-radius: 0;
                }
              }

              &.rdrDayHovered {
                border-radius: 6px!important;
              }

              .rdrStartEdge {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
              }

              .rdrEndEdge {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }

              .rdrDayStartPreview {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
              }

              .rdrDayEndPreview {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }

              .rdrInRange {
                background: rgba(75, 175, 157, 0.30);
              }

              .rdrDayNumber {
                color: #2B2C2F;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Inter, Helvetica;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                border: none;
              }

              &:not(.rdrDayPassive) {
                .rdrInRange ~ {
                  .rdrDayNumber {
                    span {
                      color: #2B2C2F;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .perCleaner {
    .perCleaner__dropdown {
      border-radius: 8px;
      border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
      padding: 8px 16px;
      cursor: pointer;

      &:focus {
        box-shadow: unset;
      }

      .calendarSvg__icon {
        padding-right: 16px;
      }

      .react-dropdown-select-dropdown {
        padding: 4px;
        border-radius: 2px;
        border: 1px solid rgba(37, 134, 100, 0.10);
        background: #E6F0EC;
        box-shadow: 0px 7px 7px -5px rgba(0, 0, 0, 0.04), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);

        span {
          color: #2B2C2F;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
          border: none;
          padding: 16px;

          &.react-dropdown-select-item-selected {
            background: #F7FBFA;
            font-weight: 600;
          }
        }
      }
    }
  }

  .customersMetrics {
    width: 100%;

    .charts__wrap {
      width: 100%;
      .charts__item {
        width: 100%;
        height: 460px;
      }
    }
  }

  .tableIncomeStatement {
    width: 100%;
    background: transparent;
    .Spreadsheet {
      width: 100%;
      background: transparent;
      overflow: scroll;

      &__table {
        tbody {
          tr {
            &:not(:first-child) {
              .Spreadsheet__cell {
                text-align: center;
              }
              .Spreadsheet__header {
                text-align: left;
              }
              th {

              }
            }
          }
        }
      }

      &__header {
        background: #E6F0EC;
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }

      &__cell {
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    &__btn_wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      &.sending {
        justify-content: center;
      }
    }
  }

  .customMonthRangePicker {
    position: relative;

    &__btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
      background: transparent;
      cursor: pointer;

      img {
        padding-right: 16px;

      }
      span {
        color: #2B2C2F;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
      }
    }

    .customMonthRangePicker__calendar {
      position: absolute;
      cursor: pointer;
      width: 400px;
      top: 50px;
      right: 0;

      .c-PJLV {
        //padding: 4px !important;
        position: relative;
        z-index: 2;
        &.c-hpOFfv-knmidH-justify-between {
          background: #E6F0EC;
        }
        .c-kdOkaC {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .c-cnmIlA {
        color: #2B2C2F;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        border: none;
        padding: 10px;

        &.c-cnmIlA-khTtio-variant-start {
          background: #4BAF9D;
          color: #fff;
        }

        &.c-cnmIlA-jqzUQi-variant-selected {
          background: rgba(75, 175, 157, 0.30);
        }
        &.c-cnmIlA-fZGkdI-variant-end {
          background: #4BAF9D;

          color: #fff;
        }
      }

    }

    .customMonthRangePicker__header {
      background: #E6F0EC;

      //
    }

    .customMonthRangePicker__month {

    }
  }

  .cashflow {
    .MuiPaper-root {
      background: transparent;

      .MuiBox-root {
        background: transparent;
      }

      .MuiTableContainer-root {
        background: transparent;

        table {
          background: transparent !important;

          tbody {
            .MuiTableCell-root {
              &:hover {
                outline: none;
                //outline: 1px solid #268664;
              }
            }
            td {
              .MuiOutlinedInput-root {
                outline: none;

                border-color: #66f;

                &:hover {
                  outline-color: #f6f;
                }
              }
            }
          }

          tr {
            background: transparent !important;
            td {
              padding: 2px 10px;
            }
          }
        }

        .MuiTable-root {
          background: transparent;
        }
      }
    }
  }
}

