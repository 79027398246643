.status {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100px;
    padding-top: 3px;
    white-space: nowrap;
  }

  &__tick {
    .status__block_inactive & {
      opacity: 0;
    }
  }

  &__label {
    font-size: 16px;
    margin: 10px 0;

    .status__block_inactive & {
      color: #d8d8d8;
    }
  }

  &__payment {
    color: #268664;
    font-size: 14px;

    .status__block_inactive & {
      color: #d8d8d8;
    }
  }

  &__date {
    color: #909090;
    font-size: 10px;
    margin-bottom: 2.5px;

    .status__block_inactive & {
      display: none;
    }
  }

  &__line {
    height: 1px;
    width: 100%;
    margin: 0 16px;
    background: #268664;

    &_inactive {
      background: #d8d8d8;
    }
  }
}
