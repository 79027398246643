.statistics__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  transition: all 0.3s ease;

  .statisticItem {
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
    background: #E6F0EC;
    transition: all 0.3s ease;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.3s ease;

      span {
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        transition: all 0.3s ease;
      }
    }
    &__value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      transition: all 0.3s ease;

      span {
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 62px;
        transition: all 0.3s ease;
      }
    }
    &__chart {
      transition: all 0.3s ease;
    }
  }
}

.schedule__wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  transition: all 0.3s ease;

  .scheduleItem {
    width: 220px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
    background: #E6F0EC;
    transition: all 0.3s ease;

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.3s ease;

      span {
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        transition: all 0.3s ease;
      }
    }
    &__value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      transition: all 0.3s ease;

      span {
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 62px;
        transition: all 0.3s ease;
      }
    }
    &__link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: all 0.3s ease;

      span {
        color: #303030;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter, Helvetica;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 62px;
        transition: all 0.3s ease;
      }
    }
  }
}

.total__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  transition: all 0.3s ease;

  &_item {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    &:nth-child(2) {
      .statisticItem {
        width: calc(33% - 10px);
      }
    }

    .statisticItem {
      width: calc(25% - 15px);
      //max-width: 280px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      border-radius: 12px;
      border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
      background: #E6F0EC;
      transition: all 0.3s ease;

      &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: all 0.3s ease;

        span {
          color: #303030;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px;
          transition: all 0.3s ease;
        }
      }
      &__value {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 62px;
        transition: all 0.3s ease;

        span {
          color: #303030;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 48px;
          font-style: normal;
          font-weight: 300;
          line-height: 62px;
          transition: all 0.3s ease;
        }
      }
      &__chart {
        transition: all 0.3s ease;
      }
    }
  }
}

.calendar__wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  transition: all 0.3s ease;

  .calendarItem {
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin: 0;
    outline: none;
    border-radius: 12px;
    border: 1px solid var(--new-stroke, rgba(37, 134, 100, 0.10));
    background: #E6F0EC;
    cursor: pointer;
    transition: all 0.3s ease;

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      transition: all 0.3s ease;

      .calendarItem__date {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transition: all 0.3s ease;

        span {
          color: #303030;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter, Helvetica;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          transition: all 0.3s ease;
        }
      }

      .calendarItem__info {
        &_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          span {
            color: #303030;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter, Helvetica;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .orderCount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -22px;
        right: -22px;
        border-radius: 50%;
        background: #258664;
        cursor: pointer;
        margin: 0;
        padding: 0;
        outline: none;
        border: none;
        transition: all 0.3s ease;

        span {
          color: #fff;
          text-align: center;
          font-family: Inter, Helvetica;
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px;
          transition: all 0.3s ease;
        }

        &.active {
          background: #E6F0EC;
          span {
            color: #303030;
          }
        }
      }
    }

    &:hover {
      //border: 1px solid rgba(37, 134, 100, 0.10);
      //background: #258664;
      border: 1px solid #258664;
      background: #D7E7E1;
      /*.calendarItem__content {
        .calendarItem__date {
          span {
            color: #fff;
          }
        }
        .calendarItem__info {
          .calendarItem__info_item {
            span {
              color: #fff;
            }
          }
        }
      }*/
    }
  }
}

.calendarWeeks__btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
  }
}

.calendarTimes {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
  width: 150px;

  &.open {
    display: flex;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 20px;
    border-radius: 6px;
    background: var(--new-light-green, #E6F0EC);
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.10);

    &_hours {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      span {
        color: #303030;
        text-align: center;
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 19px;
      }
    }
    &_cleaner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      span {
        color: #258664;
        text-align: center;
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
      }
    }
  }
}

.calendarItemDetails {
  width: 100%;

  &__title {
    color: var(--new-black, #303030);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .block {
    margin-top: 40px;
    .card__title {
      &-wrap {
        &.perCleaner {
          justify-content: space-between;
        }
      }
      &-item {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: flex-start;
      }

    }

    .quickRequests {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      &__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        margin: 0;
        border: none;
        outline: none;
        cursor: pointer;
        background: transparent;
        transition: all 0.3s ease;

        span {
          color: #3A3A3A;
          font-family: "SF Pro Display", Helvetica;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.6px;
        }

        &_times {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 10px;

          .title {
            white-space: nowrap;
          }

          .times {
            text-align: start;
          }
        }

      }
    }

  }

  .modal {
    .modalRef {
      position: relative;
      max-height: 570px;
      .btn {
        position: absolute;
        bottom: 60px;
        left: calc(50% - 65px);
      }
    }
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
}
