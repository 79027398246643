.custom-select {
  position: relative;
  width: 100%;
  height: 60px;
  font-size: 18px;
  font-weight: 300;
}

.selected-option {
  padding: 18px 16px;
  border-radius: 4px;
  border: 1px solid #e8e7e7;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &__value {
    margin-right: 10px;
  }

  &__name {
    line-height: 100%;
    letter-spacing: 0.36px;

    &.default {
      color: #d8d8d8;
    }
  }
}

.ps__rail-y {
  width: 4px;
  margin: 8px 2px 8px 4px;
  opacity: 0.9;
  display: block;

  &:hover > .ps__thumb-y,
  &:focus > .ps__thumb-y,
  &.ps--clicking .ps__thumb-y {
    width: 4px;
    background-color: #79747e;
  }

  .ps &:hover,
  .ps &:focus,
  .ps &.ps--clicking {
    background: transparent;
  }
}

.ps__thumb-y {
  border-radius: 10px;
  background-color: #79747e;
  width: 4px;
}

.options {
  &-wrapper {
    background-color: #fff;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 8px 0;
    height: 208px;

    .small & {
      height: 160px;
    }
  }

  &-list {
    list-style: none;

    & li {
      padding: 12px;
      cursor: pointer;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.5px;
      transition: all 0.3s ease;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background-color: #f7f8ff;
      }
    }
  }
}

.arrow {
  transition: transform 0.2s;
  cursor: pointer;

  &.rotated {
    transform: rotate(180deg);
  }
}
