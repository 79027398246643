.registration {
  padding-bottom: 160px;

  &__fields {
    width: 615px;
    animation: fadeIn 0.3s ease-in;

    .checkbox {
      input {
        display: none !important;
      }
    }
  }

  &__field-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  &__label {
    color: #284657;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__fill {
    margin-bottom: 20px;
  }

  &__address {
    margin-bottom: 10px;
  }
}

.summary {
  display: flex;
  margin-bottom: 10px;

  &__values {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    &:first-of-type {
      color: #1e3a8a;
      font-weight: 500;
      margin-right: 20px;
    }
  }

  &__text {
    margin-bottom: 10px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: auto;
  user-select: none;

  &:last-of-type {
    margin-bottom: 20px;
  }

  &__tick {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #258664;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;

    input[type='checkbox']:checked + & {
      background-color: #258664;
      border-color: #258664;
    }

    .invalid & {
      border: 1px solid #a51f1f;
    }
  }

  &__label {
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-left: 8px;
    cursor: pointer;
  }
}
