.container {
  width: 100%;
  margin: 0 auto;
  animation: fadeIn 0.3s ease-in;

  &.side {
    display: flex;
  }

  &.head {
    width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    //width: 1024px;
  }

  @media screen and (max-width: 1024px) {
    //width: 744px;
  }

  @media screen and (max-width: 744px) {
    width: 100%;
  }
}

.hidden {
  display: none;
}

input[type=checkbox] {
  display: block !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 16px 40px;
  height: 50px;
  line-height: normal;
  background: #268664;
  border: none;
  color: #fff;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
  animation: fadeIn 0.3s ease-in;

  &.inactive {
    background: #256951cc;
    color: #00000033;
    cursor: default;

    &:hover,
    &:active {
      background: #256951cc;
    }
  }

  &:hover {
    background: #46a382;
  }

  &:active {
    background: #268664;
  }
}

.btn-undo {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  border: none;
  position: fixed;
  bottom: 120px;
  left: calc((100vw - 1440px) / 2 + 120px);
  cursor: pointer;
  opacity: 1;

  &:hover &__label::after {
    background-color: #fff;
  }

  &:active &__label {
    color: #268664;
  }

  &:active &__label::after {
    background-color: transparent;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__label {
    margin-left: 10px;
    position: relative;
    transition: all 0.3s ease;

    &::after {
      display: block;
      content: '';
      background: transparent;
      width: 100%;
      height: 1px;
      bottom: 0;
      position: absolute;
      transition: all 0.3s ease;
    }
  }

  &__timer {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 20px;
  }
}

.input {
  border-radius: 4px;
  border: 1px solid #e8e7e7;
  background: transparent;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  transition: all 0.3s ease;
  height: 62px;
  padding: 18px 12px;

  &:active,
  &:focus,
  &:focus-visible {
    border: 1px solid #268664;
    box-shadow: 0px 0px 5px 0px rgba(35, 41, 214, 0.4);
  }
}

.invalid .invalid-field {
  border: 1px solid #a51f1f;
}

.spinner {
  margin: 200px auto 0;
  border: 10px solid #E6F0EC;
  border-radius: 50%;
  border-top: 10px solid #258664;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;

  .settings & {
    margin-top: 73px;
    margin-right: 550px;
  }

  @media screen and (max-width: 1440px) {
    width: 85px;
    height: 85px;
    border-width: 8.5px;
  }

  @media screen and (max-width: 1024px) {
    width: 70px;
    height: 70px;
    border-width: 7px;
  }

  @media screen and (max-width: 744px) {
    width: 50px;
    height: 50px;
    border-width: 5px;
  }

  &_small {
    width: 53px;
    height: 53px;
    margin: 0;
    border-width: 7px;

    .pricing & {
      margin-top: 60px;
    }

    @media screen and (max-width: 744px) {
      width: 43px;
      height: 43px;
      border-width: 6px;
      margin-top: 40px;
    }
  }
}

.icon {
  cursor: pointer;
  position: relative;
  height: 24px;

  &::after {
    display: block;
    content: '';
    background: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all 0.3s ease;
    position: absolute;
    top: -3px;
    left: -3px;
  }

  &:hover::after {
    background: rgba(35, 41, 214, 0.03);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    &::after {
      background: rgba(35, 41, 214, 0.1);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.workingStatus {
  &__modal {
    .MuiBackdrop-root {
      background-color: transparent !important;
    }
  }
  &__content {
    position: absolute !important;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    //width: 90px;
    //height: 130px;

    border-radius: 8px;
    background: #E6F0EC;

    &_time {
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    &.time {
      height: 150px;
      overflow: scroll;
      gap: 2px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__button {
    display: flex;
    height: 24px;
    padding: 6px 12px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.4s ease;

    span {
      color: #000;
      text-align: center;
      font-size: 12px;
      fontFamily: 'Inter, sans-serif';
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: none;
    }

    &.active {
      background-color: rgba(31, 180, 46, 0.10);
    }

    &.working {
      background-color: rgba(221, 139, 16, 0.10);
    }

    &:hover {
      background-color: rgba(38, 134, 100, 0.1);
    }

    &.job {
      &.awaiting {
        background-color: rgba(164, 24, 142, 0.10);
      }
      &.progress {
        background-color: rgba(221, 139, 16, 0.10);
      }
      &.completed {
        background-color: rgba(38, 134, 100, 0.7);
      }
    }
  }
}