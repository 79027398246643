.react-tel-input {
  margin-top: 10px;

  & .form-control {
    border-radius: 4px;
    border: 1px solid #e8e7e7;
    background: transparent;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    transition: all 0.3s ease;
    color: #3a3a3a;
    width: 100%;
    padding-left: 51px;
    height: 62px;

    &::placeholder {
      color: #c0c0c0;
    }

    &.default {
      padding-left: 12px;
    }

    &:focus {
      border-color: #2329d6;
      box-shadow: 0px 0px 5px 0px rgba(35, 41, 214, 0.4);

      .invalid &.invalid-field {
        border-color: #a51f1f;
      }

      & + .flag-dropdown {
        border-color: #2329d6;
      }
    }
  }

  & .flag-dropdown {
    background-color: #fff;
    border: 1px solid #e8e7e7;
    border-right: none;
    padding: 5px;
    transition: all 0.3s ease;

    .invalid &.invalid-field {
      border: 1px solid #a51f1f;
      border-right: none;
    }
  }

  & .country-list {
    margin: 15px 0 10px -6px;
  }
}
