.stayhost {
  animation: all 0.3s ease-in;
  padding-bottom: 100px;
  width: 100%;

  &__title_wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .breadcrumbs__add_menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding-right: 16px;
      cursor: pointer;

      button {
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
        outline: none;
        border: none;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
          width: 24px;
          height: 24px;
          transition: all 0.3s ease;
        }

        &:hover {
          svg {
            transform: rotate(90deg);
          }
        }

        &.loading {
          animation: spin 1s linear infinite;
        }
      }
    }
  }

  &__title {
    color: var(--new-black, #303030);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .data {
    margin-top: 50px;
  }

  &__invoices {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pdfListTemplate {
  width: 595px;
  height: 842px;
  background-color: #fff;

  &__content {
    background: #FFF;
    padding: 16px;

    &_title {
      h1 {
        color: #268664;
        font-family: 'SF Pro Display-Semibold', Helvetica;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 46px;
        margin: 0 0 -5px -2px;
      }
      p {
        color: #268664;
        font-family: 'SF Pro Display-Light', Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 10px;
        letter-spacing: 0.3px;
      }
    }
    &_data {
      width: 100%;
      margin-top: 45px;
      padding: 24px 16px 40px;
      border: 0.5px solid #D7DAE0;

      &_title {
        display: grid;
        grid-template-columns: 80px 80px 80px 100px 190px;
        padding-bottom: 8px;
        border-bottom: 0.5px solid #D7DAE0;

        span {
          color: #268664;
          font-family: 'SF Pro Display-Semibold', Helvetica;
          font-size: 8px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0.32px;
          text-transform: uppercase;
        }
      }
      &_table {
        width: 100%;
        padding-top: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 12px;
        border-bottom: 0.5px solid #D7DAE0;

        &_item {
          width: 100%;
          display: grid;
          grid-template-columns: 80px 80px 80px 100px 190px;
          padding: 10px 0;
          min-height: 62px;
          box-sizing: border-box;
          align-items: center;
          justify-items: start;

          span {
            color: #5E6470;
            font-family: 'Inter-Regular', Helvetica;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }

      &_total {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;

        &_content {
          width: 240px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          span {
            color: #1A1C21;
            font-family: 'Inter-Bold', Helvetica;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
          }
        }

        &_sign {
          margin-top: 54px;
          width: 240px;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;
          gap: 8px;

          span {
            color: #1A1C21;
            font-family: 'Inter-Bold', Helvetica;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
          }

        }
      }
    }
  }
}

.pdfMonthInvoiceTemplate {
  width: 595px;
  height: 842px;
  background-color: #fff;

  &__content {
    width: 100%;
    height: 100%;
    background: #FFF;
    padding: 40px 40px 80px;
    position: relative;

    &_title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2px;

      h1 {
        color: #000;
        font-family: 'Mentor Bold';
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 1.14px;
        margin: 0;
        padding: 0;
      }
      p {
        color: #000;
        font-family: 'SF Pro Display-Light', Helvetica;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }
    }

    &_info {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 65px;

      &_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;

        span {
          color: #000;
          font-family: 'Mentor Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &_item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          span {
            &:last-child {
              width: 152px;
            }
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 4px;

        span {
          color: #000;
          font-family: 'Mentor Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
        }
      }
    }

    &_data {
      width: 100%;
      margin-top: 25px;

      &_title {
        display: grid;
        grid-template-columns: 260px 78px 78px 78px;
        padding: 20px 0 20px 20px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        //border-bottom: 0.5px solid #D7DAE0;

        &.second {
          grid-template-columns: 88px 88px 230px 88px;
        }

        span {
          color: #000;
          font-family: 'Mentor Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0.36px;
          text-transform: uppercase;
        }
      }
      &_table {
        width: 100%;
        display: grid;
        grid-template-columns: 260px 78px 78px 78px;
        padding: 20px 0 20px 20px;

        &.second {
          padding: 10px 0 10px 20px;
          grid-template-columns: auto;
          .pdfMonthInvoiceTemplate__content_data_table_item {
            width: 100%;
            display: grid;
            grid-template-columns: 88px 88px 230px 88px;
            box-sizing: border-box;
            align-items: center;
            justify-items: start;

            span {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }

        span {
          color: #000;
          font-family: 'Mentor Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
        }

        &_period {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          span {
            color: #000;
            font-family: 'Mentor Regular';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
          }
        }

      }

      &_total {
        width: 100%;
        display: grid;
        grid-template-columns: auto 78px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 20px 0 20px 20px;

        &.second {
          grid-template-columns: auto;
          row-gap: 8px;

          .pdfMonthInvoiceTemplate__content_data_total_item {
            width: 100%;
            display: grid;
            grid-template-columns: auto 88px;
          }
        }

        span {
          color: #000;
          font-family: 'Mentor Regular';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0.36px;
          text-transform: uppercase;
          text-align: start;

          &:last-child {
            text-align: start;
          }
        }
      }

      &_footer {
        margin-top: 130px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        &_left {
          width: 170px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 4px;

          span {
            color: #000;
            font-family: 'Mentor Regular';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            text-align: start;
          }
        }

        &_right {
          width: 265px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          gap: 4px;

          &_item {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            padding: 20px 0;
            border-bottom: 1px solid #000;

            span {
              color: #000;
              font-family: 'Mentor Regular';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0.36px;
              text-transform: uppercase;
            }
          }
        }
      }

    }
    &_page {
      position: absolute;
      bottom: 40px;
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        color: #000;
        font-family: 'Mentor Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.36px;
      }
    }

  }
}

.pdfListInvoiceTemplate {
  width: 595px;
  height: 842px;
  background-color: #fff;

  &__content {
    background: #FFF;
    padding: 24px 16px 74px;

    &_title {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      h1 {
        color: #268664;
        font-family: 'SF Pro Display-Semibold', Helvetica;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 46px;
        margin: 0 0 -5px -2px;
      }
      p {
        color: #268664;
        font-family: 'SF Pro Display-Light', Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 10px;
        letter-spacing: 0.3px;
      }
    }

    &_info {
      margin-top: -15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      span {
        color: #5E6470;
        font-family: 'Inter-Light', Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 14px;
      }

      &_row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 40px;

        &.fl {
          padding-bottom: 8px;
        }

        &_employee {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 4px;

          &_item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }
      }
    }

    &_data {
      width: 100%;
      margin-top: 40px;
      padding: 17px 16px;
      border: 0.5px solid var(--Gray-100, #D7DAE0);
      background: var(--White, #FFF);

      &_title {
        width: 100%;
        display: grid;
        grid-template-columns: 80px 100px 271px 80px;
        padding-bottom: 9px;
        border-bottom: 0.5px solid #D7DAE0;

        span {
          color: #5E6470;
          font-family: 'Inter-Semibold', Helvetica;
          font-size: 8px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0.32px;
          text-transform: uppercase;
        }
      }
      &_table {
        width: 100%;
        padding: 9px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0.5px solid #D7DAE0;

        &_item {
          width: 100%;
          display: grid;
          grid-template-columns: 80px 100px 271px 80px;
          //padding: 10px 0;
          //min-height: 62px;
          box-sizing: border-box;
          align-items: center;
          justify-items: start;

          span {
            color: #5E6470;
            font-family: 'Inter-Regular', Helvetica;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;

            &:nth-child(3) {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      &_total {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        margin-top: 9px;

        span {
          color: #268664;
          font-family: 'Inter-Semibold', Helvetica;
          font-size: 8px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0.32px;
          text-transform: uppercase;

          &:first-child {
            text-align: center;
            width: 100px;
          }
          &:last-child {
            text-align: start;
            width: 80px;
          }
        }
      }
    }

    &_text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      margin-top: 12px;

      span {
        color: #5E6470;
        font-family: 'Inter-Light', Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 14px;
      }
    }

    &_signature {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      margin-top: 60px;

      &_content {
        width: 200px;
        padding-top: 8px;
        border-top: 0.5px solid var(--Gray-100, #D7DAE0);
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #1A1C21;
          font-family: 'Inter-Semibold', Helvetica;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
        }
      }
    }
  }
}
