.breadcrumbs {
  margin-left: 40px;
  display: flex;
  position: relative;
  z-index: 2;
  height: 24px;
  transition: all 0.3s ease;
  animation: fadeIn 0.3s ease-in;

  .settings & {
    margin-bottom: 60px;
    margin-left: 0;
  }

  .data &,
  .registration & {
    margin-bottom: 20px;
    margin-left: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    & a {
      color: #c8c8c8;
      font-size: 10px;
      line-height: normal;
      position: relative;
      padding-bottom: 1px;

      &::after {
        display: block;
        content: '';
        background: #c8c8c8;
        width: 100%;
        height: 1px;
        bottom: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  &__icon {
    margin: 0 6px;
  }

  &__add_menu {
    display: none;
  }
}

.breadcrumbsModal {
  &__modal {
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
  &__content {
    position: absolute !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 0px;
    flex-direction: column;
    width: 395px;
    gap: 20px;

    background: #FFF;
    box-shadow: 0px 0px 21px 5px rgba(0, 0, 0, 0.08);
  }
  &__options_title {
    padding: 0 60px;
    span {
      color: #258664;
      font-family: "SF Pro Display";
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 23px */
      letter-spacing: 1.15px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  &__button {
    display: flex;
    height: 66px;
    padding: 20px 60px !important;
    align-items: flex-start;
    justify-content: flex-start !important;
    cursor: pointer;
    transition: all 0.4s ease;
    width: 100%;
    text-transform: unset !important;

    span {
      color: #3A3A3A;
      font-size: 20px;
      font-family: "SF Pro Display-Semibold", Helvetica;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 26px */
      letter-spacing: 0.6px;
    }

    &.active {
      background-color: rgba(31, 180, 46, 0.10);
    }

    &.working {
      background-color: rgba(221, 139, 16, 0.10);
    }

    &:hover {
      background-color: #F7FBFA !important;
    }
  }
}
