.card {
  padding-bottom: 60px;

  & * {
    box-shadow: none !important;
  }

  /*&__block {
    border-radius: 20px;
    background: #fff;
    padding: 40px;
    margin-bottom: 60px;

    &:first-of-type {
      padding-top: 94px;
      margin-top: -54px;
    }
  }*/

  &__back {
    cursor: pointer;
    margin-right: 40px;
  }

  &__title {
    color: #258664;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;

    &-wrap {
      display: flex;
      align-items: center;
      user-select: none;
      transition: all 0.4s ease;
      margin-bottom: 30px;

      .block & {
        cursor: pointer;
      }

      .registration & {
        margin-bottom: 20px;
      }
    }
  }

  &__step {
    color: #284657;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  &__activity {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-start;
    margin-left: 10px;
  }

  &__status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    align-self: flex-start;
    margin-left: 10px;

    &.Inactive {
      background: #ea1919;
    }

    &.Working {
      background: #f98f12;
    }

    &.Active {
      background: #268664;
    }
  }
}

.personal {
  .cleaning & {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__wrap {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s ease;
    user-select: none;

    .personal.expanded & {
      margin-bottom: 16px;
    }
  }

  &__title {
    color: #268664;
    font-size: 20px;
    font-weight: 500;
  }

  &__info {
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;

    .personal.expanded & {
      max-height: 1000px;
    }
  }

  &__values {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      font-weight: 500;
      color: #284657;
      margin-right: 20px;

      & a {
        color: #284657;

        &:hover {
          color: #268664;
        }
      }
    }
  }

  &__text {
    margin-bottom: 8px;
    align-self: flex-start;

    &_warning {
      font-weight: 500;
      margin-right: 8px;
      color: #be0f0f;
    }
  }

  &__add {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    align-self: flex-start;

    &-icon {
      margin-top: -2px;
    }

    &-label {
      margin-left: 6px;
      color: #268664;
      font-size: 16px;
      font-weight: 500;
      margin-top: -2px;
    }
  }
}

span.personal__text:last-of-type {
  margin-bottom: 0;
}

.arrow {
  margin-right: 10px;
  transition: transform 0.4s ease;
  cursor: pointer;

  &.rotated {
    transform: rotate(180deg);
  }
}

.kpi {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  &__block {
    padding: 20px;
    height: 94px;
    border-radius: 6px;
    border: 1px solid #2329d61a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .notifications &,
    .cleaner & {
      width: calc((100% - 60px) / 7);
    }

    .agent & {
      width: calc((100% - 50px) / 6.2);

      &:nth-of-type(3) {
        width: calc((100% - 50px) / 5);
      }
    }

    .address &,
    .subscription & {
      width: calc((100% - 40px) / 5);
    }
  }

  &__label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  &__value {
    color: #284657;
    font-size: 20px;
    font-weight: 500;
    align-self: flex-start;
  }
}

.block {
  /*&__table {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    overflow: hidden;
    transition: main 0.3s ease;
    margin-top: 20px;

    .block.expanded & {
      max-height: 5000px;
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }*/
}

.notificationCard {
  &__block {
    //background-color: rgba(102, 102, 255, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;

    .cardItem{
      &__container {
        width: 195px;
        height: 422px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.4s ease;
        cursor: pointer;
        border-radius: 20px;
        border: 2px solid #268664;

        &:hover {
          //scale: 1.02;
          -webkit-box-shadow: 0px 0px 40px 5px rgba(38, 134, 100, 0.15);
          -moz-box-shadow: 0px 0px 40px 5px rgba(38, 134, 100, 0.15);
          box-shadow: 0px 0px 40px 5px rgba(38, 134, 100, 0.15) !important;
        }
      }
      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 30px 16px 6px 16px;
      }
      &__header_title {
        color: #3D3C42;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
        height: 100%;

        .item_data {
          //height: 284px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .title {
            color: #3A3A3A;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.26px;
          }
          .description {
            color: #3A3A3A;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 120%;
            letter-spacing: 0.5px;
          }
        }

        .item_buttons {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .item_button {
            display: flex;
            width: 100%;
            padding: 6px 0;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            background: #268664;

            &.second {
              background: rgba(38, 134, 100, 0.10);
            }

            .item_btn {
              color: #fff;
              text-align: center;
              font-size: 9px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.45px;

              &.second {
                color: #268664;
              }
            }
          }
        }
      }
      &__tab_bottom {
        /*display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0 28px 0;*/

        display: grid;
        grid-template-columns: auto auto auto;
        padding: 8px 0 14px 0;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;

          .title {
            color: #D1DCD8;
            text-align: center;
            //font-family: "SF Pro Display";
            font-size: 6px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.072px;

            &.active {
              color: #268664;
            }
          }
        }
      }
    }
  }
}

.modal {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  transition: all 0.5s ease-out;
  overflow-y: scroll;
  z-index: 3;
  animation: fadeIn 0.3s ease-in;

  &.active {
    display: flex;
  }

  .modalRef {
    //margin-top: 10%;
    margin-top: 5%;
    width: 700px;
    min-height: 570px;
    height: fit-content;
    background-color: #fff;
    padding: 20px 20px 60px 20px;

    &.export {
      min-height: unset;
      margin-top: 20%;
    }

    .modal__close {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .close_icon {
        cursor: pointer;
        transition: all 0.3s ease-out;
        &:hover {
          scale: 1.1;
          transform: rotate(180deg);
        }
      }

    }
    .modal__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 10px;

      .text {
        color: #258664;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 23px */
        letter-spacing: 1.15px;
      }
      &.brdc {
        padding-bottom: 60px;
        .text {
          color: #303030;
        }
      }
    }

    .form {
      .modalForm {
        &__fields {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          padding: 20px 70px;
        }
        &__field-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 5px;
        }
        &__label {
          color: #3A3A3A;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 130%; /* 20.8px */
          letter-spacing: 0.48px;
        }
        &__input {
          display: flex;
          padding: 18px 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #E8E7E7;

          color: #3A3A3A;
          font-family: "SF Pro Display-Light", Helvetica;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 100%; /* 16px */
          letter-spacing: 0.64px;
        }
        &__dropdown {
          width: 520px;
          display: flex;
          padding: 18px 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #E8E7E7;

          color: #3A3A3A;
          font-family: "SF Pro Display-Light", Helvetica;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 100%; /* 16px */
          letter-spacing: 0.64px;

          &:focus {
            box-shadow: unset !important;
          }

          &:focus-within {
            box-shadow: unset !important;
          }

          .react-dropdown-select-dropdown {
            width: 100% !important;
            top: 55px !important;

            &:focus {
              box-shadow: unset !important;
            }

            .react-dropdown-select-dropdown-position-bottom {
              width: 100% !important;
              height: 300px;
            }
          }
        }
        &__btn {
          display: flex;
          min-width: 120px;
          padding: 20px 40px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border: none;
          border-radius: 6px;
          background: #258664;
          cursor: pointer;

          span {
            color: #FFF;
            font-family: Inter, "Helvetica";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
          }

          &.inactive {
            cursor: not-allowed;
            background: #256951cc;
            span {
              color: #00000033;
             }
          }
        }
        &__checkbox {
          margin: 0 !important;
          input {
            display: none !important;
          }
        }
        &__dateTime {
          display: flex;
          padding: 18px 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #E8E7E7;

          color: #3A3A3A;
          font-family: "SF Pro Display-Light", Helvetica;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 100%; /* 16px */
          letter-spacing: 0.64px;

          .react-datetime-picker__wrapper {
            border: none;
            width: 100%;
          }

        }
      }
    }

    .modal__select_buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 40px 0;
      height: 115px;
      transition: all 0.2s ease-out;

      .modal__select_button_item {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #C6D7CA;
        border: none;
        transition: all 0.2s ease-out;
        cursor: pointer;
        img {
          display: none;
        }

        span {
          color: #303030;
          font-family: Inter, Helvetica;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &.active {
          padding: 8px;
          gap: 8px;
          background: #268664;

          img {
            display: block;
          }

          span {
            color: #fff;
          }
        }
      }
    }

    .modal__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      transition: all 0.2s ease-out;

      .btn {
        border-radius: 8px;
        border: 1px solid rgba(37, 134, 100, 0.10);
        background: var(--new-green, #258664);

        &.cancel {
          background: #C6D7CA;

          &:hover {
            background: rgba(70, 163, 130, 0.7);
          }
        }

        &:hover {
          background: #46a382;
        }
      }
    }
  }
}

.react-datetime-picker__calendar {
  &--open {
    .react-calendar {
      padding: 4px;
      border-radius: 2px;
      border: 1px solid rgba(37, 134, 100, 0.10);
      background: #FFF;
      box-shadow: 0px 7px 7px -5px rgba(0, 0, 0, 0.04), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);

      .react-calendar__navigation {
        background-color: #E6F0EC;

        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next2-button {
          display: none;
        }

        button {
          font-size: 18px;
        }
        .react-calendar__navigation__label {
          span {
            color: #2B2C2F;
            font-family: Inter, Helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
          }
        }
      }

      .react-calendar__viewContainer {
        .react-calendar__month-view {
          .react-calendar__month-view__weekdays {
            .react-calendar__month-view__weekdays__weekday {
              abbr {
                text-decoration: none;
                color: #2B2C2F;
                text-align: center;
                font-feature-settings: 'liga' off, 'clig' off;

                font-family: Inter, Helvetica;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
              }
            }
          }

          .react-calendar__month-view__days {
            .react-calendar__month-view__days__day {
              padding: 4px 12px;
              margin: 4px 0;
              abbr {
                text-decoration: none;
                color: #2B2C2F;
                text-align: center;
                font-feature-settings: 'liga' off, 'clig' off;

                font-family: Inter, Helvetica;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
              }
              &.react-calendar__month-view__days__day--neighboringMonth {
                abbr {
                  color: rgba(43, 44, 47, 0.20);
                }
              }
            }
          }
        }
      }
    }
  }
}
.react-datetime-picker__button {
  &:hover {
    .react-datetime-picker__button__icon {
      stroke: #268664;
    }
  }
  &:enabled {
    &:hover {
      .react-datetime-picker__button__icon {
        stroke: #268664;
      }
    }
  }
}

.react-datetime-picker__calendar {
  .react-calendar {
    .react-calendar__viewContainer {
      .react-calendar__tile--now {
        background: #E6F0EC;
      }
      .react-calendar__tile--active {
        background: #268664;
      }
    }
  }
}

.notifications .block:nth-last-of-type(4) .data__table,
.address .block:nth-last-of-type(1) .data__table,
.cleaner .block:nth-last-of-type(1) .data__table,
.cleaning .block:nth-last-of-type(1) .data__table,
.agent .block:nth-last-of-type(1) .data__table {
  margin-top: 0;
}
