td::after {
  display: none;
}

tbody a:hover,
tbody tr:hover {
  background-color: #f7f8ff !important;
}

.data {
  //min-height: calc(100vh - 114px);
  background-color: transparent !important;

  & * {
    box-shadow: none !important;
  }

  &__tabs {
    display: inline-flex;
    position: relative;
    z-index: 2;
    padding-bottom: 30px;

    .card & {
      margin-top: 18px;
    }
  }

  &__tab {
    width: 180px;
    height: 59px;
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #2329d61a;
    color: #284657;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    transition: all 0.2s ease;
    user-select: none;

    &:hover {
      border-color: #258664;
    }

    &:active {
      border-color: #258664;
      color: #258664;
    }

    &.active {
      color: #258664;
      border-color: #258664;
      background: #2329d608;
    }
  }

  &__table {
    margin-top: -35px;
    background-color: transparent !important;

    .MuiPaper-root {
      background-color: transparent !important;
    }
    .MuiBox-root {
      background-color: transparent !important;
      .MuiCollapse-root {
        min-height: 60px !important;
      }
    }
    .MuiTableContainer-root {
      .MuiTable-root {
        thead,
        tr,
        th,
        td {
          //background-color: transparent !important;
        }
        .MuiTableRow-root {
          background-color: #F7FBFA !important;
        }
      }
    }
  }

  &.cleaners {
    .breadcrumbs {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      transition: all 0.3s ease;

      &__add_menu {
        display: flex;
        transition: all 0.3s ease;
        cursor: pointer;

      }
    }
  }
  &.customers {
    .breadcrumbs {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      transition: all 0.3s ease;

      &__add_menu {
        display: flex;
        transition: all 0.3s ease;
        cursor: pointer;

      }
    }
  }

  &.jobs {
    .breadcrumbs {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      transition: all 0.3s ease;

      &__add_menu {
        display: flex;
        transition: all 0.3s ease;
        cursor: pointer;

      }
    }
  }

  &.requests {
    .breadcrumbs {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      transition: all 0.3s ease;

      &__add_menu {
        display: flex;
        transition: all 0.3s ease;
        cursor: pointer;

      }
    }
  }
}

.status-wrapper {
  padding: 4px 10px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  position: relative;

  &.Active,
  &.Completed {
    background-color: #1fb42e1a;
  }

  &.Working,
  &.Inprogress,
  &.Invoiced {
    background-color: #dd8b101a;
  }

  &.Awaitingconfirmation,
  &.Expired {
    background-color: #a4188e1a;
  }

  &.Cancelled {
    color: #be0f0f;
  }

  &.working {
    cursor: pointer;
    transition: all 0.3s ease-out;

    &.Inactive {
      background: transparent;
    }
    &.Active {
      background: rgba(31, 180, 46, 0.10);
    }
    &.Working {
      background: rgba(221, 139, 16, 0.10);
    }

    &:hover {
      background: rgba(37, 134, 100, 0.1);
    }
  }

  &.job {
    cursor: pointer;
    transition: all 0.3s ease-out;

    &.AwaitingConfirmation {
      background: rgba(164, 24, 142, 0.10);
    }
    &.Confirmed {
      background: rgba(37, 134, 100, 0.1);
    }
    &.InProgress {
      background: rgba(221, 139, 16, 0.10);
    }
    &.Completed {
      background: rgba(38, 134, 100, 0.7);
    }
    &:hover {
      background: rgba(37, 134, 100, 0.1);
    }
  }

  &.time {
    color: #284657;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }
}

.link {
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    background: transparent;
    width: 100%;
    height: 1px;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    color: #258664;

    & a {
      color: #258664;
    }

    &::after {
      background: #258664;
    }
  }
}

.launch-icon {
  cursor: pointer;

  & path {
    fill: rgba(#2329d6, 0.3);
    transition: all 0.3s ease;
  }

  &:hover path {
    fill: #258664;
  }
}

.reviews {
  animation: all 0.3s ease-in;
  padding-bottom: 100px;
}

.reviews__title {
  color: var(--new-black, #303030);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter, Helvetica;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.review__modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &_rooms {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;

      span {
        color: rgba(48, 48, 48, 0.5);
        font-family: Inter, Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;

        &:last-child {
          border-left: 1px solid rgba(48, 48, 48, 0.5);
          padding-left: 40px;
        }
      }
    }
  }

  .review__modal_header_text {
    color: #3A3A3A;
    font-family: 'SF Pro Display-Regular', Helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.45px;
    white-space: nowrap;
  }

  &_data {
    width: 800px;
    padding-top: 16px;
  }

  &_images {
    padding-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 25px;

    &_item {
      width: 350px;
      height: 350px;

      img {
        width: 100%;
        transition: all .3s ease-in-out;
        &:hover {
          scale: 1.2;
        }
      }
    }
  }

}