.page {
  width: 100%;
  position: relative;
  background: transparent;
  transition: all 0.3s ease;

  &.reduced {
    background: rgba(0, 9, 255, 0.03);
  }
}

.background {
  width: 50%;
  height: 100%;
  background: #E6F0EC;
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease;

  .reduced & {
    background: transparent;
  }
}

.sidebar {
  width: 208px;
  //padding: 30px 20px 30px 70px;
  padding-top: 140px;
  transition: all 0.3s ease;
  opacity: 1;

  .reduced & {
    width: 0;
    padding: 30px 0;
    opacity: 0;
    pointer-events: none;
  }

  &__project {
    color: #284657;
    font-size: 18px;
    line-height: normal;
  }

  &__list {
    //margin-top: 60px;
  }

  &__item {
    //margin-bottom: 20px;

    .sidebar__sublist & {
      //margin-bottom: 6px;
    }
  }

  &__link {
    padding: 16px 0 16px 48px;
    color: #284657;
    font-size: 18px;
    line-height: normal;
    display: block;
    transition: all 0.3s ease;
    font-weight: 500;

    &.active {
      //border-left: 1px solid #2329d6;
      //color: #2329d6;
      //padding-left: 10px;
      background-color: #258664;
      color: #fff;
    }
  }

  .sidebar__sublist-link {
    color: #303030;

    /* Size3_16_Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.active {
      text-decoration-line: underline;
    }
  }

  &__sublist {
    //display: none;
    //margin-top: 10px;
    padding: 16px 16px 16px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
}

.content {
  min-height: 100vh;
  background: #F7FBFA;
  width: calc(100% - 208px);
  padding: 0 30px;
  transition: all 0.3s ease;
  //width: 100%;
  box-sizing: border-box;

  &.expanded {
    width: 100%;
    background: transparent;
  }
}

.sidebar__burger {
  display: none;
}

@media screen and (max-width: 767px) {
  .sidebar {
    position: absolute;
    height: 100vh;
    width: 0;
    background-color: #E6F0EC;
    overflow: hidden;
    transition: all .6s ease-in-out;
    z-index: 3;
    padding-top: 0;

    &.active {
      width: 80%;
    }

    &__info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 40px 20px 60px;

      .language__value {
        font-size: 18px;
      }
      .language__variants {
        padding: 0;
      }
    }

    &__account {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 3px;

      .settings-modal__account {
        font-size: 20px;
        font-weight: 500;
        white-space: nowrap;
      }
      .settings-modal__role {
        font-size: 16px;
        font-weight: 500;
        margin-top: unset;
      }
    }
    &__link {
      padding: 10px 0 10px 20px;

      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
    }
    &__item {
      width: 100%;
    }
  }

  .sidebar__burger {
    display: block;
    position: fixed;
    top: 40px;
    right: 16px;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 30px;

    &_icon {


      &,
      &::before,
      &::after{
        width: 40px;
        height: 2px;
        background-color: #268664;
        display: inline-block;

      }

      &::before,
      &::after{
        content: "";
        position: absolute;
        left: 0;
        transition: all .2s;
      }

      &::before{
        top: 12px;
      }
      &::after{
        top: 24px;
      }
    }
  }

  .content {
    width: 100%;
    padding-top: 120px;

    .header-section {
      display: none;
    }
  }
}
