.auth {
  padding: 50px 120px 0;
  min-height: 100vh;
  border-radius: 4px;
  background: #fff;

  &__title {
    font-family: 'SF Pro Display', sans-serif;
    color: #268664;
    font-size: 60px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.6px;
    text-align: center;
    margin-top: 107px;
  }

  &__note {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    margin-top: 5px;
    animation: fadeIn 0.3s ease-in;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__fields {
    width: 615px;
    margin: 50px 0 40px;
  }

  &__field-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
  }

  &__label {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    line-height: 130%;
    letter-spacing: 0.36px;
    margin-bottom: 10px;
  }

  &__eye {
    position: absolute;
    top: 52px;
    right: 12px;
    cursor: pointer;
  }
}
