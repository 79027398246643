.market {
  animation: all 0.3s ease-in;

  .central__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.charts {
  &__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 36px;
    margin-top: 40px;
  }
  &__item {
    width: 100%;
    box-sizing: content-box;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid rgba(37, 134, 100, 0.10);
    background: #E6F0EC;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiChartsLegend-mark {
      stroke-linecap: round !important;
      border-radius: 50px !important;
    }
    .MuiChartsLegend-series {
      text {
        color: #2B2B2B !important;
        font-feature-settings: 'liga' off, 'clig' off;

        font-family: Inter, Helvetica !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 300 !important;
        line-height: 130% !important;
      }
    }
    .MuiChartsAxis-tickLabel {
      color: #2B2C2F !important;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter, Helvetica !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 300 !important;
      line-height: 130% !important;
      text-align: center;
    }
  }
}